@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

$maxExtraVerySmallDevices: "max-width: 350px";
$minExtraVerySmallDevices: "min-width: 0px";
$maxExtraSmallDevices: "max-width: 600px";
$minExtraSmallDevices: "min-width: 350px";
$maxSmallDevices: "max-width: 768px";
$minSmallDevices: "min-width: 600px";
$maxMediumDevices: "max-width: 992px";
$minMediumDevices: "min-width: 768px";
$maxLargeDevices: "max-width: 1200px";
$minLargeDevices: "min-width: 992px";
$maxExtraLargeDevices: "max-width: 2000px";
$minExtraLargeDevices: "min-width: 1200px";
.myContainer {
    @extend .container;
    max-width: 90%;
} 