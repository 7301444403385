@use "../mainScss/GuideLine" as *;
@use "../mainScss/ScreenSize" as *;

.SVGIconWhite {
    width: 15px;
    height: 15px;
    fill: map-get($colorSystem , "grayColor");

    &:hover {
        fill: map-get($colorSystem , "secondaryColorShade1");
    }
}

.HeaderSVG {
    width: 20px;
}

.FooterContentSVG {
    width: 20px;

    &:hover {
        fill: map-get($colorSystem , "secondaryColorShade1");
    }
}