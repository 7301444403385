@use "../mainScss/GuideLine" as *;
@use "./ScreenSize" as *;

.mainButton {
    white-space: pre-line;

    border: $boarderWidth solid map-get($colorSystem , "mainColor");
    background: map-get($colorSystem , "mainColor");
    color: map-get($colorSystem , "primaryColor");
    font-size: map-get($typeSystem , "lableFontSize");
    padding: 10px 25px;

    &:hover {
        border: $boarderWidth solid map-get($colorSystem , "secondaryColorShade1");
        color: map-get($colorSystem , "secondaryColorShade1");
    }

    float: var(--selectedFloat, left);

}

.mainButtonLight {
    @extend .mainButton;
    background: #ffffff00;
    border: $boarderWidth solid map-get($colorSystem , "primaryColor");

}

.mainButtonDark {
    @extend .mainButton;
    background: #ffffff00;
    color: map-get($colorSystem , "primaryColor");
    border: $boarderWidth solid map-get($colorSystem , "primaryColor");

}

.exploreMore {
    @extend .mainButton;
    color: map-get($colorSystem , "mainColor");
    background: #ffffff00;
    border: 0px;
    //svg color
    fill: map-get($colorSystem , "mainColor");

    //border-bottom: 1px solid map-get($colorSystem , "mainColor" );
    &:hover {
        border: 0px;
        //border-bottom: 1px solid map-get($colorSystem , "secondaryColorShade1" );
        color: map-get($colorSystem , "secondaryColorShade1");
        //svg hover color
        fill: map-get($colorSystem , "secondaryColorShade1");

    }

    //diamond top right button
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: 2px;
        right: 2px;
        width: 8px;
        height: 8px;
        border-radius: 5%;
        transform: rotate(45deg);
        background: map-get($colorSystem , "secondaryColorShade1");
    }

}

.svgIcon {

    margin: 0px 15px 0px 0px;
    transform: var(--selectedMirrorSVG, scale(1, -1));


    /*
    fill: map-get($colorSystem , "secondaryColorShade1" ); 
    &:hover{
        fill: map-get($colorSystem , "secondaryColorShade1" );  
    }*/

}

.exploreMoreDark {
    @extend .exploreMore;
    fill: map-get($colorSystem , "grayColor");
    color: map-get($colorSystem , "grayColor");

}

@media ($minExtraSmallDevices) and ($maxExtraSmallDevices) {

    .mainButton {
        font-size: map-get($typeSystem , "lableFontSizeSmall");
    }

    .exploreMore {
        &:before {
            width: 6px;
            height: 6px;
        }

    }
}

@media ($minExtraVerySmallDevices) and ($minLargeDevices) {

    .mainButtonLight {
        border-radius: 4px;

        padding: 3px 8px;
        // height: 100%;

    }
}