
.alert-card{
    border-radius: 5vw;
    position: relative;
    width: 50vw;
    padding: 5vw;
}
@media (max-width: 768px) {
    .alert-card{
    width: 95%;
    margin-right: 15%;
    padding: 0vw;

    }
}