@use "./GuideLine" as *;

label {
    font: normal normal bold;
    @extend .bodyFontSize;
    color: map-get($colorSystem , "primaryColor");
    margin: $marginElement;
    display: inline-block;
    width: 100%;
    padding-top: 10px;
}

input,
select,
textarea {
    /* Layout Properties */
    width: 100%;
    padding: $padding1;
    /* UI Properties */
    color: map-get($colorSystem , "primaryColor");
    // background: map-get($colorSystem , "whiteColor" ) 0% 0% no-repeat padding-box;
    @extend .bodyFontSize;
    border: 0px;
    // border-bottom: $boarderWidth solid map-get($colorSystem , "linkAndIconColor" );

    &:focus {
        background: map-get($colorSystem , "whiteColor") 0% 0% no-repeat padding-box;
        // border: $boarderWidth solid map-get($colorSystem , "secondaryColorShade1" );
        outline: none;

    }

    ::placeholder {
        color: map-get($colorSystem , "primaryColor");
        opacity: 1;
        /* Firefox */

    }
}




/*
.paragraphInput{

}

.dateInput{

}

.picklistInput{

}

.cheackboxInput{

}

.uploadInput{
    
}*/